<template>

    <div class="user2">
        <h4>个人资料</h4>
        <div class="info">
            <table>
               
                <tr>
                    <td>真实姓名</td>
                    <td>{{userview.real_name | msg}}</td>
                </tr>
                <tr>
                    <td>性 别</td>
                    <td>{{userview.sex | sexshow}}</td>
                </tr>
                <tr>
                    <td>手机号码</td>
                    <td>{{userview.phone}} <span @click="tourl3">更换手机号</span></td>
                </tr>
                <tr>
                    <td>电子邮箱</td>
                    <td>{{userview.mailbox | msg}} <span @click="toemail">绑定邮箱</span></td>
                </tr>
                <tr>
                    <td>出生日期</td>
                    <td>{{userview.birthday | msg}} </td>
                </tr>
                <tr>
                    <td>证件信息</td>
                    <td>
                        <div v-for="(item,i) in userview.card_id" :key="i" style="display:flex;color:#666">
                            <p style="width:80px;color:#666">{{item.type}}</p>
                            <p  style="color:#666">{{item.card_id}}</p>
                        </div>

                    </td>
                </tr>
                <tr>
                    <td>个性签名</td>
                    <td>{{userview.autograph | msg}}</td>
                </tr>
            </table>
            <div class="tou">
                <!-- <div class="img">
                    <img src="../../assets/user/my2.png" alt="">
                </div> -->
                <el-upload
				class="avatar-uploader"
				:action='baseURL+"index.php/index/file/upload"'
				:show-file-list="false"
				name="image"
				:on-success="handleAvatarSuccess"
				:before-upload="beforeAvatarUpload">
				<img v-if="userview.avatar" :src="userview.avatar" class="avatar">
				<i v-else class="el-icon-plus avatar-uploader-icon"></i>
				 <div >
                     <p class="uptou"> 修改头像</p>
                </div>
				</el-upload>
                
            </div>
        </div>


        <div class="but">
            <div class="edit" @click="tourl2">
                编辑
            </div>
            <div class="editpass" @click="tourl('/user/editpassword')">
                修改登录密码
            </div>
        </div>
    </div>

</template>

<script>
import { userViewAPI,userEditAPI} from "@/api/user";
import {baseURL} from "@/http/base"
export default {

  data () {
    return {
      sign: 1,
      userview:{},
      baseURL:baseURL  
    }
  },
  created () {
      this.getuserview()
  },
  filters:{
      msg(info){
          if (info=='' || info==null) {
              return '未设置'
          }
          return info
      }
  },
  methods: {
    tourl (url) {
      this.$router.push({ path: url })
    },
     tourl2 () {
      this.$router.push({ path: '/user/editpersoninfo' })
    },
     tourl3 () {
      this.$router.push({ path: '/user/editphone' })
    },
    toemail(){
        this.$router.push({ path: '/user/editemail' })
    },
    getuserview(){		
        userViewAPI().then(res=>{
            this.userview=res.user
        })
	},
    handleAvatarSuccess(res, file) {
		  console.log(res)
        this.userview.avatar = res.data.file_url;
        if(res.code==200){
            userEditAPI({avatar:res.data.file_url}).then(res=>{
                 this.$message.success('上传成功');
            })
        }
        
      },
      beforeAvatarUpload(file) {
          console.log(file)
        const isJPG = file.type === 'image/jpeg' ||
                       file.type === 'image/jpg' ||
                        file.type === 'image/png'
        const isLt2M = file.size / 1024 / 1024 < 5;

        if (!isJPG) {
          this.$message.error('上传头像图片只能是 jpeg/png/jpg 格式!');
          return false
        }
        if (!isLt2M) {
          this.$message.error('上传头像图片大小不能超过 5MB!');
           return false;
        }
       return true;
      },
  }
}
</script>

<style lang="scss" scoped="scoped">

	.user2{
		padding: 5px 20px 20px;
		background: #ffffff;
		h4{
			font-size: 18px;
			font-weight: 500;
			color: #333333;
			font-weight: 500;
			line-height: 45px;
			
		}
        .info{
            display: flex;
            justify-content: space-between;
        }
        table{
            td{
                line-height: 40px;
               color: #666;
            }
            td:nth-child(odd){
                width: 130px;
                text-align: justify;
                text-align-last: justify;
               padding-right: 50px;
               color: #333333;
               
            }
            span{
                color: #ff7c00;
                margin-left: 5px;
                cursor: pointer;
            }
        }
        .tou{
              width: 130px;
              margin-right: 20px;
            // .img{
            //      width: 120px;
            //     height: 120px;
            //     opacity: 1;
            //     background: #f0f0f0;
            //     border-radius: 4px;
            //     img{
            //         width: 120px;
            //     }
            // }
            .uptou{
                width: 96px;
                height: 24px;
                opacity: 1;
                border: 1px solid #ff7c00;
                border-radius: 3px;
                text-align: center;
                color: #ff7c00;
                line-height: 24px;
                margin: 20px auto;
            }
        }
        
        .but{
            display: flex;
            margin-top: 50px;
            div{
                width: 150px;
                height: 45px;
                border-radius: 4px;
                font-size: 18px;
                font-weight: 500;
                text-align: center;
               
                line-height: 45px;
            }
            .edit{
                 color: #ffffff;
                background: #ff7c00;
                margin-right: 30px;
            }
            .editpass{
                color: #ff7c00;
                border: 1px solid #ff7c00;
            }
        }
		
	}

</style>
<style>
.avatar-uploader .el-upload {
    /* border: 1px dashed #d9d9d9; */
    
    cursor: pointer;
    position: relative;
    overflow: hidden;
  }
 .avatar-uploader img{
     border-radius: 6px;
     border: 1px dashed #d9d9d9;
  }
  .avatar-uploader img:hover {
    border-color: #409EFF;
  }
   .avatar-uploader-icon {
    font-size: 28px;
    color: #8c939d;
    width: 130px;
    height: 130px;
    line-height:130px;
    text-align: center;
  }
  .avatar {
    width: 130px;
    height: 130px;
    display: block;
  }
</style>